import Vue from 'vue';
import { marketManage } from '@/service/apis';

export default function openAdjustDialog(type, { userAccount, growthValue, points }, callback) {
  growthValue = Number(growthValue);
  points = Number(points);
  const [tag, api, compareValue] = {
    integral: ['积分', marketManage.pointsManualAdjust, points],
    growthValue: ['成长值', marketManage.growthManualAdjust, growthValue],
  }[type];
  const vm = new Vue({
    data() {
      return {
        model: {
          number: '',
          remark: '',
        },
        show: true,
        rules: {
          number: [
            {
              required: true,
              message: `请输入调整${tag}的数量`,
              trigger: 'blur',
            },
            {
              validator(_, val, cb) {
                if (!val) {
                  return cb(new Error(`请输入调整${tag}的数量`));
                }
                const value = Number(val);
                if (Number.isNaN(value)) {
                  return cb(new Error('请输入数字'));
                }
                if (value === 0) {
                  return cb(new Error('数量不能为0'));
                }
                if (compareValue === 0) {
                  if (value <= 999999 && value > 0) {
                    return cb();
                  }
                  return cb(new Error(`${tag}调整数量只能在0到999999之间`));
                }
                if (
                  (value >= -compareValue && value < 0) ||
                  (value <= 999999 - compareValue && value > 0)
                ) {
                  return cb();
                }
                return cb(
                  new Error(`${tag}调整数量只能在-${compareValue}到${999999 - compareValue}之间`)
                );
              },
              trigger: 'blur',
            },
          ],
        },
        onFormInput() {
          console.log('onFormInput');
        },
        beforeCancel: () => {
          this.$destroy();
        },
        beforeConfirm: () => {
          const form = this.$refs.YkcForm;
          if (form) {
            console.log('form', form);
            form.validate(valid => {
              if (valid) {
                console.log('valid', valid);
                const data = { ...this.model, userAccount };
                api(data).then(res => {
                  console.log('调整res', res);
                  this.$destroy();
                  callback();
                });
              }
            });
          }
        },
      };
    },
    beforeDestroy() {
      this.show = false;
      document.body.removeChild(this.$el);
    },
    render() {
      const { model, rules, show } = this;
      console.log('this.model', this.model);
      const title = `${tag}调整`;
      return (
        <YkcOperationDialog
          title={title}
          show={show}
          beforeCancel={this.beforeCancel}
          beforeConfirm={this.beforeConfirm}
          vOn:close-dialog={this.beforeCancel}>
          <template slot="content">
            {/* 由于jsx限制，这里需要显式通过props传递 */}
            <YkcForm ref="YkcForm" props={{ model }} rules={rules}>
              <YkcFormItem label={title} prop="number">
                <YkcInput
                  placeholder={`请输入调整${tag}的数量`}
                  vModel={model.number}
                  onChange={val => {
                    if (String(val) === '-') {
                      model.number = String(val);
                    } else {
                      model.number = Math.round(
                        Number.isNaN(Number(val || 1)) ? 1 : Number(val || 1)
                      );
                    }
                  }}></YkcInput>
              </YkcFormItem>
              <YkcFormItem label="备注" prop="remark">
                <YkcInput
                  maxlength={500}
                  placeholder="请输入调整备注"
                  vModel={model.remark}></YkcInput>
              </YkcFormItem>
            </YkcForm>
          </template>
        </YkcOperationDialog>
      );
    },
  });
  vm.$mount();
  document.body.appendChild(vm.$el);
}
