<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        title="会员列表"
        :data="tableData"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in pageButtons">
              <ykc-button
                :type="btn.type"
                v-if="i != 0 && btn.enabled()"
                :key="i"
                @click="btn.handleClick">
                {{ btn.text }}
              </ykc-button>
              <el-dropdown @command="handleCommand" v-if="i == 0 && btn.enabled()" :key="i">
                <ykc-button :type="btn.type" v-if="btn.enabled()">
                  {{ btn.text }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </ykc-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="beforeHandleCommand('levelConfig')"
                    v-if="codePermission('URL:operation:member:grade')">
                    等级配置
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="beforeHandleCommand('growthValueRule')"
                    v-if="codePermission('operation:member:list:growth')">
                    成长值规则
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="beforeHandleCommand('integralRule')"
                    v-if="codePermission('operation:member:list:integral')">
                    积分规则
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageIndex"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      :title="drawerTitle"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button
              style="margin-left: 8px"
              :type="btn.type"
              v-if="btn.enabled()"
              :key="i"
              @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <GrowthValueRule
          v-if="editType === 'growthValueRule'"
          :growthValueRule="growthValueRule"
          ref="GrowthValueRule"></GrowthValueRule>
        <IntegralRule
          :integralRule="integralRule"
          v-if="editType === 'integralRule'"
          ref="IntegralRule"></IntegralRule>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import GrowthValueRule from './GrowthValueRule.vue';
  import IntegralRule from './IntegralRule.vue';
  import { marketManage } from '@/service/apis';
  import openAdjustDialog from './dialog';
  import { localGetItem, offlineExport, code as codePermission } from '@/utils';

  export default {
    name: 'VIPList',
    components: {
      IntegralRule,
      GrowthValueRule,
    },
    data() {
      return {
        growthValueRule: null,
        integralRule: null,
        showDrawer: false,
        editType: '',
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              const vm =
                this.editType === 'growthValueRule'
                  ? this.$refs.GrowthValueRule
                  : this.$refs.IntegralRule;
              console.log('vm', vm);
              vm &&
                vm.submitForm(() => {
                  this.showDrawer = false;
                  this.searchTableList();
                });
            },
          },
        ],
        pageButtons: [
          {
            id: '0',
            text: '规则配置',
            type: 'plain',
            enabled: () =>
              this.codePermission('URL:operation:member:grade') ||
              this.codePermission('operation:member:list:growth') ||
              this.codePermission('operation:member:list:integral'),
            handleClick: () => {
              this.add();
            },
          },
          {
            id: '1',
            text: '导出',
            type: 'plain',
            enabled: () => this.codePermission('operation:member:list:export'),
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableOperateButtons: [
          {
            enabled: () => this.codePermission('operation:member:list:detail'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.gotoDetail(row);
                  }}>
                  详情
                </ykc-button>
              );
            },
          },
          {
            enabled: () => this.codePermission('operation:member:list:editintegral'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.integralAdjust(row);
                  }}>
                  积分调整
                </ykc-button>
              );
            },
          },
          {
            enabled: () => this.codePermission('operation:member:list:editgrowth'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.growthValueAdjust(row);
                  }}>
                  成长值调整
                </ykc-button>
              );
            },
          },
        ],
        tableColumns: [
          { label: '会员编码', prop: 'memberId', minWidth: '200px' },
          { label: '会员账号', prop: 'userAccount', minWidth: '200px' },
          { label: '会员姓名', prop: 'userName', minWidth: '200px' },
          { label: '会员手机号', prop: 'userPhone', minWidth: '200px' },
          { label: '会员等级', prop: 'levelName', minWidth: '200px' },
          { label: '成长值', prop: 'memberGrowthValue', minWidth: '100px' },
          { label: '积分', prop: 'memberPoints', minWidth: '100px' },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          memberId: '',
          userAccount: '',
          userName: '',
          userPhone: '',
          level: '',
        },
      };
    },
    computed: {
      drawerTitle() {
        if (this.editType === 'growthValueRule') {
          return '成长值规则';
        }
        if (this.editType === 'integralRule') {
          return '积分规则';
        }
        return '';
      },
      searchData() {
        const { member_level = [] } = localGetItem('dictionary');
        console.log('member_level', member_level);
        return [
          {
            comName: 'YkcInput',
            key: 'memberId',
            label: '会员编号',
            placeholder: '请输入会员编号',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '会员账号',
            placeholder: '请输入会员账号',
          },
          {
            comName: 'YkcInput',
            key: 'userName',
            label: '会员姓名',
            placeholder: '请输入会员姓名',
          },
          {
            comName: 'YkcInput',
            key: 'userPhone',
            label: '会员手机号',
            placeholder: '请输入会员手机号',
          },
          {
            comName: 'YkcDropdown',
            key: 'level',
            label: '会员等级',
            placeholder: '请选择会员等级',
            data: member_level.map(({ code, name }) => ({ id: code, name })),
          },
        ];
      },
    },
    created() {
      this.searchTableList();
    },
    methods: {
      codePermission,
      /**
       * 动态设置Dropdown的command
       */
      beforeHandleCommand(flag) {
        // flag为传递的参数
        return {
          flag,
        };
      },
      handleCommand(command) {
        console.log('command+++', command);
        if (command.flag === 'levelConfig') {
          window.open('/operationCenter/vipManage/vipLevel', '_blank');
        } else if (command.flag === 'growthValueRule') {
          marketManage.showGrowthRuleConfig({}).then(res => {
            console.log('showGrowthRuleConfig res', res);
            this.growthValueRule = res;
            this.editType = 'growthValueRule';
            this.showDrawer = true;
          });
        } else if (command.flag === 'integralRule') {
          marketManage.showPointsRuleConfig({}).then(res => {
            console.log('showPointsRuleConfig res', res);
            this.integralRule = res;
            this.editType = 'integralRule';
            this.showDrawer = true;
          });
        }
      },
      exportTableData() {
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'member_list_export',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /** 积分调整 */
      integralAdjust(row) {
        console.log('integralAdjust row', row);
        openAdjustDialog('integral', row, this.searchTableList);
      },
      /** 成长值调整 */
      growthValueAdjust(row) {
        console.log('growthValueAdjust row', row);
        openAdjustDialog('growthValue', row, this.searchTableList);
      },
      gotoDetail(row) {
        this.$router.push({
          path: '/operationCenter/vipManage/vipManage/details',
          query: {
            id: row.userId,
          },
        });
      },
      searchTableList() {
        const data = {
          ...this.searchParams,
          size: this.pageInfo.pageSize,
          current: this.pageInfo.pageIndex,
        };
        marketManage.memberQueryForPage(data).then(res => {
          console.log('memberQueryForPage res', res);
          const { records, current, total } = res;
          records.forEach(item => {
            item.memberPoints = String(item.memberPoints);
            item.memberGrowthValue = String(item.memberGrowthValue);
            item.points = String(item.memberPoints);
            item.growthValue = String(item.memberGrowthValue);
          });
          this.tableData = records;
          this.pageInfo.pageIndex = current;
          this.pageInfo.total = total;
        });
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /** 点击查询按钮 */
      handleSearchConfirm() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            // 点击确认取消保存后，要重置表单
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
    },
  };
</script>

<style lang="scss" scoped></style>
