<template>
  <ykc-form ref="YkcForm" :model="model" class="growth-value-rule" :rules="rules">
    <ykc-form-item label="获取规则" prop="firstGainRule">
      <div class="row">
        <ykc-radio
          v-model="firstRowSelected"
          :data="firstRowData"
          @click.native="handleRadioClick('first')"></ykc-radio>
        <div>订单满</div>
        <ykc-input
          v-model="firstRowOrderValue"
          type="number"
          placeholder="请输入"
          @change="
            val =>
              (firstRowOrderValue = Math.round(
                Number.isNaN(Number(val || 1)) ? 0 : Number(val || 1)
              ))
          ">
          >
        </ykc-input>
        <div>元，获取订单金额</div>
        <ykc-input
          v-model="firstRowPercentage"
          type="number"
          placeholder="请输入"
          @change="
            val =>
              (firstRowPercentage = Math.round(
                Number.isNaN(Number(val || 0)) ? 0 : Number(val || 0)
              ))
          "></ykc-input>
        <div>%的成长值</div>
      </div>
    </ykc-form-item>
    <ykc-form-item class="secondGainRule" prop="secondGainRule">
      <div class="row">
        <ykc-radio
          v-model="secondRowSelected"
          :data="secondRowData"
          @click.native="handleRadioClick('second')"></ykc-radio>
        <div>订单满</div>
        <ykc-input
          v-model="secondRowOrderValue"
          type="number"
          placeholder="请输入"
          @change="
            val =>
              (secondRowOrderValue = Math.round(
                Number.isNaN(Number(val || 1)) ? 0 : Number(val || 1)
              ))
          "></ykc-input>
        <div>度，获取订单度数</div>
        <ykc-input
          v-model="secondRowPercentage"
          type="number"
          placeholder="请输入"
          @change="
            val =>
              (secondRowPercentage = Math.round(
                Number.isNaN(Number(val || 0)) ? 0 : Number(val || 0)
              ))
          "></ykc-input>
        <div>%的成长值</div>
      </div>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { marketManage } from '@/service/apis';

  export default {
    props: ['growthValueRule'],
    created() {
      console.log('this.growthValueRule', this.growthValueRule);
      if (this.growthValueRule && typeof this.growthValueRule === 'object') {
        if (String(this.growthValueRule.ruleType) === '1') {
          this.firstRowSelected = '1';
          this.firstRowOrderValue = String(this.growthValueRule.ruleValue);
          this.firstRowPercentage = String(this.growthValueRule.gainPercent);
        } else if (String(this.growthValueRule.ruleType) === '2') {
          this.secondRowSelected = '1';
          this.secondRowOrderValue = String(this.growthValueRule.ruleValue);
          this.secondRowPercentage = String(this.growthValueRule.gainPercent);
        }
      }
    },
    data() {
      return {
        model: {
          firstGainRule: '',
          secondGainRule: '',
        },
        firstRowSelected: '',
        firstRowData: [
          {
            value: '1',
            id: '1',
            name: '',
          },
        ],
        firstRowOrderValue: '',
        firstRowPercentage: '',
        secondRowSelected: '',
        secondRowData: [
          {
            value: '1',
            id: '1',
            name: '',
          },
        ],
        secondRowOrderValue: '',
        secondRowPercentage: '',
      };
    },
    computed: {
      rules() {
        return {
          firstGainRule: [
            {
              // eslint-disable-next-line
              validator: (rule, value, callback) => {
                if (this.firstRowSelected) {
                  if (!this.firstRowOrderValue) {
                    callback(new Error('请输入订单金额'));
                  } else if (
                    Number(this.firstRowOrderValue) <= 0 ||
                    Number(this.firstRowOrderValue) >= 9999
                  ) {
                    callback(new Error('请输入大于0小于9999的订单金额'));
                  }
                  if (!this.firstRowPercentage) {
                    callback(new Error('请输入成长值'));
                  } else if (
                    Number(this.firstRowPercentage) < 0 ||
                    Number(this.firstRowPercentage) > 100
                  ) {
                    callback(new Error('获取成长值比例范围为0-100'));
                  }
                  callback();
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          secondGainRule: [
            {
              // eslint-disable-next-line
              validator: (rule, value, callback) => {
                if (!this.firstRowSelected && !this.secondRowSelected) {
                  callback(new Error('请选择获取规则'));
                } else if (this.secondRowSelected) {
                  if (!this.secondRowOrderValue) {
                    callback(new Error('请输入订单金额'));
                  } else if (
                    Number(this.secondRowOrderValue) <= 0 ||
                    Number(this.secondRowOrderValue) >= 9999
                  ) {
                    callback(new Error('请输入大于0小于9999的订单金额'));
                  }
                  if (!this.secondRowPercentage) {
                    callback(new Error('请输入成长值'));
                  } else if (
                    Number(this.firstRowPercentage) < 0 ||
                    Number(this.firstRowPercentage) > 100
                  ) {
                    callback(new Error('获取成长值比例范围为0-100'));
                  }
                  callback();
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
        };
      },
    },
    methods: {
      handleRadioClick(type) {
        if (type === 'first') {
          this.secondRowOrderValue = '';
          this.secondRowPercentage = '';
          this.secondRowSelected = '';
        } else {
          this.firstRowOrderValue = '';
          this.firstRowPercentage = '';
          this.firstRowSelected = '';
        }
      },
      // eslint-disable-next-line no-unused-vars
      submitForm(callback) {
        console.log('this.$refs.YkcForm', this.$refs.YkcForm);
        this.$refs.YkcForm.validate(valid => {
          console.log('valid', valid);
          if (valid) {
            const data = {
              ruleType: this.firstRowSelected ? '1' : '2',
              ruleValue: this.firstRowSelected ? this.firstRowOrderValue : this.secondRowOrderValue,
              gainPercent: this.firstRowSelected
                ? this.firstRowPercentage
                : this.secondRowPercentage,
            };
            marketManage.saveGrowthRuleConfig(data).then(res => {
              console.log('saveGrowthRuleConfig res', res);
              callback();
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  .growth-value-rule {
    .row {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .ykc-input.el-input {
        margin: 0 8px;
        width: 80px;
      }
      .secondGainRule {
        margin-top: -16px;
        label {
          display: none;
        }
      }
    }
  }
</style>
