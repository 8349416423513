var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ykc-form',{ref:"YkcForm",staticClass:"growth-value-rule",attrs:{"model":_vm.model,"rules":_vm.rules}},[_c('ykc-form-item',{attrs:{"label":"获取规则","prop":"firstGainRule"}},[_c('div',{staticClass:"row"},[_c('ykc-radio',{attrs:{"data":_vm.firstRowData},nativeOn:{"click":function($event){return _vm.handleRadioClick('first')}},model:{value:(_vm.firstRowSelected),callback:function ($$v) {_vm.firstRowSelected=$$v},expression:"firstRowSelected"}}),_c('div',[_vm._v("订单满")]),_c('ykc-input',{attrs:{"type":"number","placeholder":"请输入"},on:{"change":val =>
            (_vm.firstRowOrderValue = Math.round(
              Number.isNaN(Number(val || 1)) ? 0 : Number(val || 1)
            ))},model:{value:(_vm.firstRowOrderValue),callback:function ($$v) {_vm.firstRowOrderValue=$$v},expression:"firstRowOrderValue"}},[_vm._v(" > ")]),_c('div',[_vm._v("元，获取订单金额")]),_c('ykc-input',{attrs:{"type":"number","placeholder":"请输入"},on:{"change":val =>
            (_vm.firstRowPercentage = Math.round(
              Number.isNaN(Number(val || 0)) ? 0 : Number(val || 0)
            ))},model:{value:(_vm.firstRowPercentage),callback:function ($$v) {_vm.firstRowPercentage=$$v},expression:"firstRowPercentage"}}),_c('div',[_vm._v("%的成长值")])],1)]),_c('ykc-form-item',{staticClass:"secondGainRule",attrs:{"prop":"secondGainRule"}},[_c('div',{staticClass:"row"},[_c('ykc-radio',{attrs:{"data":_vm.secondRowData},nativeOn:{"click":function($event){return _vm.handleRadioClick('second')}},model:{value:(_vm.secondRowSelected),callback:function ($$v) {_vm.secondRowSelected=$$v},expression:"secondRowSelected"}}),_c('div',[_vm._v("订单满")]),_c('ykc-input',{attrs:{"type":"number","placeholder":"请输入"},on:{"change":val =>
            (_vm.secondRowOrderValue = Math.round(
              Number.isNaN(Number(val || 1)) ? 0 : Number(val || 1)
            ))},model:{value:(_vm.secondRowOrderValue),callback:function ($$v) {_vm.secondRowOrderValue=$$v},expression:"secondRowOrderValue"}}),_c('div',[_vm._v("度，获取订单度数")]),_c('ykc-input',{attrs:{"type":"number","placeholder":"请输入"},on:{"change":val =>
            (_vm.secondRowPercentage = Math.round(
              Number.isNaN(Number(val || 0)) ? 0 : Number(val || 0)
            ))},model:{value:(_vm.secondRowPercentage),callback:function ($$v) {_vm.secondRowPercentage=$$v},expression:"secondRowPercentage"}}),_c('div',[_vm._v("%的成长值")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }